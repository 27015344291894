<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Accounting Transaction</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Accounting Transaction</li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="overlay" v-if="is_loading">
        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="overlay" v-if="is_loading">
              <i class="fas fa-2x fa-sync-alt fa-spin"></i>
            </div>
            <div class="card-header">
              <h3 class="card-title">Detail</h3>
              <div class="card-tools">
                <router-link class="btn btn-info" :to="{name: 'AccountingTransactionList'}">
                  <i class="fas fa-list"></i> List
                </router-link>
              </div>
            </div>
            <div class="card-body table-responsive p-0">
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <td><b>ID</b></td>
                    <td>{{ value.id }}</td>
                  </tr>
                  <tr>
                    <td><b>Transaction type</b></td>
                    <td>{{ value.transaction_type }}</td>
                  </tr>
                  <tr>
                    <td><b>Source-Id</b></td>
                    <td>{{ value.source_id }}</td>
                  </tr>
                  <tr>
                    <td><b>Description</b></td>
                    <td>{{ value.description }}</td>
                  </tr>
                  <tr>
                    <td><b>Transaction date</b></td>
                    <td>{{ value.transaction_date }}</td>
                  </tr>
                  <tr>
                    <td><b>Entry date</b></td>
                    <td>{{ value.entry_date }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { CBSAccountingApi } from '@/services/api';
export default {
  name: 'AccountingTransactionShow',
  data: () => ({
    value: {},
    pre: {},
    is_loading: false
  }),
  mounted: function () {
    this.initialize()
  },
  methods: {
    getDetail: function (id) {
      CBSAccountingApi.showAccountingTransaction(id)
        .then((item) => {
          this.value = item
        })
        .catch((error) => {
          this.$toaster.error(error.response.data.message)
        })
        .finally(() => {
          this.is_loading = false
        })
    },
    async initialize () {
      this.is_loading = true
      this.params = this.$route.params
      await this.getDetail(this.params.id)
      this.is_loading = false
    }
  }
}
</script>
